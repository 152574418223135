/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { EventHandler, ReactElement } from "react";
import { useSearchParams } from "react-router-dom";

import {
  DriveItem,
  ThumbnailSet,
  Thumbnail,
  NullableOption,
} from "@microsoft/microsoft-graph-types";
import { ViewMode } from "./DirectoryList";
import { CombinedItem, getCaptionForCombinedItem } from "../graph";
import SVG_FolderOpened from "../svg/FolderOpened.svg";
import SVG_OneLevelUp from "../svg/OneLevelUp.svg";
import SVG_OneDrive from "../svg/OneDrive.svg";
import { stringify } from "querystring";

const getSize = (
  set: ThumbnailSet,
  viewMode: ViewMode
): NullableOption<Thumbnail> | undefined => {
  if (viewMode === "small") return set.small;
  else if (viewMode === "medium") return set.medium;
  else if (viewMode === "large") return set.large;
  else return set.source;
};

const DirectoryEntry = (imageSource:string, folderClass:string, caption:string, selected:boolean, oneDriveUrl:string|null, onClick: (e:React.MouseEvent<Element>)=>void) => {
  var oneDriveLink:ReactElement|null = null;
  if( oneDriveUrl != null ) {
    oneDriveLink = (<a href={oneDriveUrl}>
      <img
            src={SVG_OneDrive}
            width="16"
            height="16"
            alt="open in OpenDrive"
          />
        </a>);
  }

  return (
    <div className={'folder '+folderClass + (selected?' selected':'')} onClick={onClick}>
      <img className='image' src={imageSource} alt={caption} />
      {oneDriveLink}
      <div className="caption">{caption}</div>
  </div>);
}

export const DriveItemComponent = (props: {
  viewMode: ViewMode;
  combinedItem: CombinedItem;
  parentLocation: string;
  onSelect: () => void;
  selected: boolean;
}): JSX.Element => {
  var imageTag: JSX.Element | null = null;

  let [_, setSearchParams] = useSearchParams();

  var imageType = 'icon';
  var imageUrl = '';

  var driveItem = props.combinedItem.driveItem;

  if (driveItem.thumbnails != null && driveItem.thumbnails.length > 0) {
    var thumbnailZero = driveItem.thumbnails[0];
    imageUrl = getSize(thumbnailZero, props.viewMode)?.url ?? '';
    imageType = 'thumb';
  }

  const targetUrl = props.parentLocation === "/"
    ? "/" + driveItem.name
    : props.parentLocation + "/" + driveItem.name;

  var clickItem: ((e:React.MouseEvent<Element>)=>void)|null = null;

  if( driveItem.folder) {
    imageUrl = SVG_FolderOpened;
    clickItem = () => {
      setSearchParams({ path: targetUrl });
    };
  } else {
    clickItem=(e:any) => {
      props.onSelect();
      e.stopPropagation();
    }
  }

  var caption = getCaptionForCombinedItem(props.combinedItem);

  return DirectoryEntry( imageUrl, imageType, caption, props.selected, null, clickItem );
};

export const DriveItemComponentParent = (props: {
  parentLocation: string;
}): JSX.Element => {
  let [_, setSearchParams] = useSearchParams();

  const parentOf = (location: string): string => {
    var bits = location.split("/");
    bits.pop();
    return bits.join("/");
  };
  const clickItem = () => {
    setSearchParams({ path: parentOf(props.parentLocation) });
  };

  return DirectoryEntry( SVG_OneLevelUp, 'icon', 'Parent', false, null, clickItem );
};

import { graphConfig } from "./authConfig";
import { GetAccessToken } from "./authConfig";
import { User,DriveItem, Folder } from "@microsoft/microsoft-graph-types";
import { stringify } from "querystring";


function getBaseOptions() : RequestInit {
  const headers = new Headers();
  const bearer = `Bearer ${GetAccessToken()}`;

  headers.append("Authorization", bearer);

  return {
      method: "GET",
      headers: headers
  };
}

export async function fetchUserMe() : Promise<User>{ 
    var response = await fetch(graphConfig.graphMeEndpoint, getBaseOptions())
    return response.json();
}

const convertPathForURL = (path : string) => {
  if( path==='' || path ==='/') {
    return 'root';
  } else {
    return 'root:/' + path.replaceAll('/','%2f') + ':';
  }
}

export interface MetadataItem {
  Filename: string,
  Description:string|null
}

export interface MetadataJSON {
  ShowFileNames:boolean,
  Thumbnail:string|null,
  Title:string|null,
  Descriptions: MetadataItem[]
}

export interface CombinedItem {
  driveItem : DriveItem,
  metadata : MetadataItem
}

export interface FolderData {
  rootItem:DriveItem,
  path:string,
  items: CombinedItem[],
  metadata: MetadataJSON
};

export async function fetchDirectory(path:string) : Promise<FolderData>{ 

  const graphUri = graphConfig.rootDirectoryEndpoint + convertPathForURL(path) + '/?$expand=thumbnails,children($expand=thumbnails)'
  console.log('Requesting ' + graphUri);

  var response = await fetch( graphUri, getBaseOptions())
  var rootItem = (await response.json()) as DriveItem;
  var childItems = rootItem.children ?? [];

  var infoIndex = childItems.findIndex( x => x.name && x.name.toLowerCase() === 'dirlist.json')

  var metadataJSON : MetadataJSON = {ShowFileNames:true, Descriptions:[],Thumbnail:null,Title:null};
  if(infoIndex >= 0 )
  {
    var infoItem = childItems[infoIndex];
    childItems.splice(infoIndex,1);
    
    const graphUri = graphConfig.rootDirectoryEndpoint + '/items/' + infoItem.id + '/content?preferNoRedirect=true';
    var infoContent = await fetch( graphUri, getBaseOptions())
    metadataJSON = await infoContent.json() as MetadataJSON;
  }

  var combinedItems = childItems.map( childItem => {
    var metaDataItem = metadataJSON.Descriptions.find( md => md.Filename.toLowerCase() == childItem.name?.toLowerCase());
    if( metaDataItem == null ) {
      metaDataItem = {Filename:childItem.name||'', Description:null};
      metadataJSON.Descriptions.push(metaDataItem);
    }
    return {driveItem:childItem, metadata:metaDataItem };
  });

  return {path,rootItem,metadata:metadataJSON, items:combinedItems};
}

export async function saveMetadataToCloud(folderData:FolderData ){
  if( folderData.items.length < 1 ) 
    throw Error('There must be some other files in order to save metadata');
  
  const parentFolderId = folderData.items[0].driveItem.parentReference?.id;
  //const parentFolder = folderData.items[0].driveItem.parentReference?.driveId;

  const graphUri = graphConfig.rootDirectoryEndpoint + 'items/' + parentFolderId + ':/dirlist.json:/content'

  console.log('Requesting ' + graphUri);

  const options : RequestInit = getBaseOptions();
  options.method='PUT';
  options.mode='cors';
  (options.headers as any)['Content-Type'] = 'application/json';
  options.body = JSON.stringify( folderData.metadata );

  var response = await fetch( graphUri, options)
  console.log(JSON.stringify(response));
}

export function getCaptionForCombinedItem(combinedItem: CombinedItem) {
  var driveItem = combinedItem.driveItem;
  var onedriveMetadata: any = driveItem?.listItem?.fields;
  var oneDriveTitleField : (string|null) = (onedriveMetadata?.Title as string) ?? null;
  var oneDriveTitle = oneDriveTitleField ? `${driveItem.name} - ${oneDriveTitleField}` : driveItem.name;
  return combinedItem.metadata.Description ?? oneDriveTitle ?? '?';
}

export const msalConfig = {
  auth: {
    clientId: "e80ccb98-82c7-4a14-a05b-a9b459ddf687",
    authority: "https://login.microsoftonline.com/2125c11b-ad59-469f-b629-32f975a2e670", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    //redirectUri: "https://photos.edonica.com/",
    //redirectUri: "http://localhost:3000/",
    // redirectUri: "https://photos.goward.com/"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read","Files.ReadWrite.All","Sites.Read.All"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    rootDirectoryEndpoint_OLD: "https://graph.microsoft.com/v1.0/drives/b!0-za2qa5-UmaRn7CJJsrnRCY4dvJh_pMmH3Hvt9WiR8Hg7Zfx1mpTKuSD-4ztq71/items/root:/Documents:/children",
    rootDirectoryEndpoint_2020_06: "https://graph.microsoft.com/v1.0/sites/goward.sharepoint.com/drive/root:/Photos%2f2022%2f06:/children?expand=thumbnails",
    rootDirectoryEndpoint_2022: "https://graph.microsoft.com/v1.0/sites/goward.sharepoint.com/drive/root:/Photos%2f2022:/children?expand=thumbnails",
    rootDirectoryEndpoint: "https://graph.microsoft.com/v1.0/sites/goward.sharepoint.com/drive/"
};

var accessToken : string|null = null;

export const GetAccessToken = () : string => {
  if( accessToken === null ) {
    throw new Error("Attempting to fetch access token when we are not logged in ");
  }
  return accessToken;
}

export const SetAccessToken = (value:string) => { accessToken = value; }

import React, { SetStateAction, useEffect, useState, Dispatch } from "react";
import { BrowserRouter, useParams, useSearchParams} from 'react-router-dom';
//import Navbar from "react-bootstrap/Navbar";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import { loginRequest, GetAccessToken, SetAccessToken } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { CombinedItem, fetchDirectory, fetchUserMe, FolderData, saveMetadataToCloud } from "./graph";
import { User } from "@microsoft/microsoft-graph-types";

import { SignInButton } from "./components/SignInButton";
import { DirectoryList, ViewMode } from "./components/DirectoryList";
import { ItemDetails } from "./components/ItemDetails";
import { SignOutButton } from "./components/SignOutButton";

function DirectoryBrowser(props:{
  browserParams : URLSearchParams, 
  // selectedItem:CombinedItem|null, 
  // setSelectedItem:Dispatch<SetStateAction<CombinedItem|null>>
  // folderData: FolderData|null,
  // setFolderData: (folderData:FolderData)=>void  
}) : JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
const [userProfileData, setUserProfileData] = useState<User>();
const [viewMode, setViewMode] = useState<ViewMode>('large');

const [selectedItem,setSelectedItem] = useState<CombinedItem|null>(null);
const [folderData, setFolderData] = useState<FolderData|null>(null);

  var pathName = props.browserParams.get('path');

  useEffect(() => {
    const authRequestParams = {
      ...loginRequest,
      account: accounts[0],
    };
    instance.acquireTokenSilent(authRequestParams).then(async (response) => {
      SetAccessToken(response.accessToken);
      var profile = await fetchUserMe();
      setUserProfileData(profile);
    });
  }, [accounts,instance]);

  // const pathName = (props.pathName && props.pathName !== '') ? props.pathName : '/';

  var loadDirectoryList = async () => {
    const directory = await fetchDirectory(pathName ?? '/');
    setFolderData(directory);
    setSelectedItem(null);
  }

  useEffect(() => {
    const authRequestParams = {
      ...loginRequest,
      account: accounts[0],
    };
    instance.acquireTokenSilent(authRequestParams).then(async (response) => {
      SetAccessToken(response.accessToken);

      loadDirectoryList();
    });
  }, [accounts,instance,pathName,props]);

  if (!userProfileData) {
    return <div>Requesting profile data</div>;
  }

  const onClickHandlerForSize = (viewSize:ViewMode) => () => {
    setViewMode(viewSize);
  }

  var dirListElement = null;
  if( folderData) {
    dirListElement =  (<DirectoryList pathName={pathName} viewMode={viewMode} selectedItem={selectedItem} setSelectedItem={setSelectedItem} folderData={folderData} setFolderData={setFolderData} />);
  }


function handleLogin(instance:any) {
    instance.loginRedirect(loginRequest).catch( (e:any) => {
        console.error(e);
    });
}

function handleLogout(instance:any) {
    instance.logoutRedirect().catch((e:any) => {
        console.error(e);
    });
}



  return (
    <>
    <Navbar bg="primary" variant="dark" expand="md">
      <Container>
        <Navbar.Brand>{folderData?.path??''}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title='View' >
              <NavDropdown.Item active={viewMode==='small'} onClick={onClickHandlerForSize('small')}>Small Images</NavDropdown.Item>
              <NavDropdown.Item active={viewMode==='medium'} onClick={onClickHandlerForSize('medium')}>Medium Images</NavDropdown.Item>
              <NavDropdown.Item active={viewMode==='large'} onClick={onClickHandlerForSize('large')}>Large Images</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href={folderData?.rootItem?.webUrl??''} target='_blank'>Edit/sync folder with sharepoint</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={userProfileData.givenName} >
              <NavDropdown.Item onClick={() => handleLogout(instance)}>Sign out</NavDropdown.Item>
            </NavDropdown>
          </Nav>

        </Navbar.Collapse>
        </Container>
    </Navbar>


    {dirListElement}
    <ItemDetails selectedItem={selectedItem} setSelectedItem={setSelectedItem} saveMetadataAction={() => {
      if(folderData!=null) {
        saveMetadataToCloud(folderData);
        loadDirectoryList();
      }
    }}/>
    </>
  );
}

function MainApp() : JSX.Element {
  const [browserParams] = useSearchParams();



  return (
    <>
    <DirectoryBrowser browserParams={browserParams} />
    </>
  )
}

function App() : JSX.Element{
  return (
    <BrowserRouter>
        <AuthenticatedTemplate>
          <MainApp />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div>It doesn't look like you're logged in.</div>
          <div>Please login with this button:</div>
          <div>
            <SignInButton />
          </div>
        </UnauthenticatedTemplate>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest, GetAccessToken, SetAccessToken } from "../authConfig";
import { DriveItemComponent, DriveItemComponentParent } from "./DriveItemComponent";
import { fetchDirectory, FolderData, CombinedItem } from "../graph";

export type ViewMode = 'small'|'medium'|'large'|'table';

export function DirectoryList( props: 
    {pathName:string|null, 
      viewMode:ViewMode, 
      selectedItem:CombinedItem|null, 
      setSelectedItem:Dispatch<SetStateAction<CombinedItem|null>>
      folderData: FolderData,
      setFolderData: (folderData:FolderData)=>void
    }) : JSX.Element {
  const { instance, accounts } = useMsal();

  if(props.folderData == null) {
    return <div></div>
  }

  var directoryItems = props.folderData.items.map( (entry,n) => {
      function onSelect() {
        props.setSelectedItem( entry );
      }
      return <DriveItemComponent viewMode={props.viewMode} combinedItem={entry} key={n} parentLocation={props.folderData.path} onSelect={onSelect} selected={props.selectedItem === entry } />
    });

  if( props.folderData.path !== '/') {
    directoryItems.unshift(<DriveItemComponentParent key='..' parentLocation={props.folderData.path} />)
  }

  return (
      <div id="Directory" className={'directoryList ' + props.viewMode} onClick={e => {
        props.setSelectedItem( null );
        e.stopPropagation();
      }} >
        {directoryItems}
      </div>
  );
}


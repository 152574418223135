import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
} from "react";
import { CombinedItem, getCaptionForCombinedItem } from "../graph";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import SVG_Close from '../svg/Close.svg'
import { DriveItemComponent } from "./DriveItemComponent";
import { graphConfig } from "../authConfig";
import {saveMetadataToCloud} from '../graph'

interface EditState {
  textChanged: Boolean;
  selectedItem : CombinedItem | null;
  currentCaption: string;

}

export const ItemDetails = (props: {
  selectedItem: CombinedItem | null;
  setSelectedItem: Dispatch<SetStateAction<CombinedItem | null>>;
  saveMetadataAction: (()=>void);
}): JSX.Element => {

  const [editState, setEditState] = useState<EditState>( {textChanged:false, selectedItem:null, currentCaption:''} );

  async function UpdateCaption(item:CombinedItem, caption:string ) {
    item.metadata.Description = caption;
    await props.saveMetadataAction();

    console.warn(`***Updating caption for ${item.driveItem.name} to ${caption}`);
  }

  if( editState.selectedItem != props.selectedItem ) {
    if( editState.textChanged ) {
      if( editState.selectedItem != null ) {
        UpdateCaption(editState.selectedItem, editState.currentCaption );
      }
    }
    setEditState( {
      textChanged:false, 
      selectedItem:props.selectedItem, 
      currentCaption:(props.selectedItem?getCaptionForCombinedItem(props.selectedItem):'')
    });
  }

  if (props.selectedItem == null) {
    return <></>;
  }

  const close = (): void => {
    props.setSelectedItem(null);
  };

  var handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEditState( {selectedItem:editState.selectedItem, textChanged:true, currentCaption: event.target.value } );
  };

  var handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    console.log("Form submit!");
    event.preventDefault();
    if( editState.selectedItem != null && editState.textChanged ) {
      UpdateCaption(editState.selectedItem, editState.currentCaption );
      setEditState({currentCaption:editState.currentCaption, textChanged:false, selectedItem:editState.selectedItem});
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <div className="itemDetails">
        <div className="titlebar">
          <div className="title">File Details <a href={editState.selectedItem?.driveItem.webUrl??''} target='_blank'>{editState.selectedItem?.driveItem?.name}</a></div>
          <img className="button" src={SVG_Close} width='16' height='16' onClick={close} alt="Close Details"/>
        </div>
        <div className={'properties '+  (editState.textChanged?'changed':'unchanged')} >
          <textarea className = "caption"
            id="inputCaption"
            value={editState.currentCaption}
            onChange={handleChange}
          ></textarea>
          <input type="submit" value="Save" className="submitButton"/>
        </div>
    </div>
    </form>
  );
};
